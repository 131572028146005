import { usePage } from '@inertiajs/vue3';
import { trans } from 'laravel-vue-i18n';

const page = usePage<any>();

export enum PermissionType {
	CANDIDATE_MANAGE = 'candidate_manage',
	PROJECT_MANAGE = 'project_manage',
	PIPELINE_MANAGE = 'pipeline_manage',
}

export const toggleSwitchLabel = (toggleSwitch: boolean): string => {
	return toggleSwitch ? trans('global.disable') : trans('global.enable');
};

export const searchString = (search: string, value: string = ''): boolean => {
	return value.toLowerCase().replace(/\s+/g, '').includes(search);
};

export const userCan = (permission: PermissionType): boolean => {
	return (page.props.auth.user?.permissions ?? []).find((item: any) => item === permission) !== undefined;
};
