<template>
	<div v-if="loaded" class="flex flex-col w-full h-full">
		<div class="flex w-full h-full justify-between items-center">
			<div class="h-full w-full">
				<Doughnut ref="chart" :data="chartData" :options="options" />
			</div>
			<div v-if="showLegend" class="w-full h-full flex flex-col justify-center pl-4">
				<div
					v-for="(label, index) in chartData.labels"
					:key="index"
					class="mb-2 pb-2 last:pb-0 last:mb-0 border-b border-sg-grey-200 last:border-b-0"
				>
					<div class="flex items-center justify-between">
						<div class="flex items-center">
							<span class="w-2 h-2 rounded-full mr-2" :style="{ backgroundColor: generatedColors[index] }"></span>
							<span class="text-j16-regular text-sg-dark-grape-100">{{ label }}</span>
						</div>
						<span class="bg-sg-grape-100 text-j16-regular text-sg-dark-grape-100 px-2.5 py-0.5 rounded-md">{{
							chartData.datasets[0].data[index]
						}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import axios from 'axios';
import { faker } from '@faker-js/faker';
import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import themes from 'daisyui/src/theming/themes';

const customLabelsPlugin = {
	id: 'customLabels',
	afterDraw: (chart, args, options) => {
		if (options.draw && typeof options.draw === 'function') {
			options.draw(chart);
		}
	},
};

ChartJS.register(ArcElement, Tooltip, Legend, Title, customLabelsPlugin);

export default {
	name: 'DoughnutChart',
	components: { Doughnut },
	props: {
		url: {
			type: String,
			default: undefined,
		},
		totalLabel: {
			type: String,
			default: 'Total',
		},
		baseColor: {
			type: Object,
			default: () => ({ r: 39, g: 0, b: 79 }),
		},
		showLegend: {
			type: Boolean,
			default: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		allDataZero: {
			type: Boolean,
			default: false,
		},
		totalFitPercent: {
			type: Number,
			default: 0,
		},
		totalAverageScore: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			loaded: false,
			title: null,
			chartData: null,
			target: document.querySelector('html'),
			lightTheme: null,
			currentTheme: null,
			observer: null,
			totalValue: 0,
		};
	},
	computed: {
		titleColor() {
			return this.currentTheme ? themes[this.currentTheme].secondary : null;
		},
		generatedColors() {
			return this.generateColors(this.baseColor, this.chartData ? this.chartData.labels.length : 0);
		},
		totalText() {
			if (this.allDataZero) {
				return 'N/A';
			}
			if (this.totalFitPercent > 0) {
				return `${this.totalFitPercent}%`;
			}
			if (this.totalAverageScore) {
				return this.totalAverageScore;
			}
			return String(this.totalValue);
		},
		options() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				cutout: '70%',
				plugins: {
					title: {
						display: true,
						fullSize: true,
						text: this.title,
						color: this.titleColor,
						font: {
							family: 'Jost',
							size: 18,
						},
						padding: 0,
					},
					colors: {
						forceOverride: true,
					},
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false,
					},
					customLabels: {
						draw: this.drawLabelsAndIndicators,
					},
				},
				layout: {
					padding: {
						top: 10,
						right: 10,
						bottom: 25,
						left: 10,
					},
				},
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
			};
		},
	},
	async mounted() {
		ChartJS.register(Tooltip, Legend, Title, ArcElement);
		this.lightTheme = import.meta.env.VITE_DAISYUI_THEME_LIGHT ?? 'light';
		this.changeTheme();
		this.target = document.querySelector('html');
		this.observer = new MutationObserver((mutationList) => {
			if (mutationList[0].attributeName === 'data-theme') {
				this.changeTheme();
			}
		});
		this.observer.observe(this.target, { attributes: true });
		this.loaded = false;
		try {
			let response;
			if (Object.values(this.data).length > 0) {
				response = { data: this.data };
			} else if (this.url) {
				response = await axios.get(this.url);
			} else {
				response = { data: this.generateMockData() };
			}
			this.chartData = response.data;
			this.chartData.datasets[0].backgroundColor = this.generatedColors;
			this.title = response.data.title || '';
			this.totalValue = this.calculateTotal(response.data.datasets[0].data);
			this.loaded = true;
		} catch (e) {
			console.error(e);
		}
	},
	unmounted() {
		this.observer && this.observer.disconnect();
	},
	methods: {
		changeTheme() {
			this.currentTheme = document.querySelector('html').getAttribute('data-theme') ?? this.lightTheme;
		},
		calculateTotal(data) {
			const sum = data.reduce((sum, value) => sum + value, 0);
			return Number.isInteger(sum) ? sum : sum.toFixed(2);
		},
		generateColors(baseColor, count) {
			const colors = [];
			for (let i = 0; i < count; i++) {
				const newColor = this.incrementRGB(baseColor.r, baseColor.g, baseColor.b, i);
				colors.push(`rgb(${newColor.r},${newColor.g},${newColor.b})`);
			}

			return colors;
		},
		increment(value, index, strength) {
			const res = Math.round(value + (255 - value) * (index * strength));

			if (res >= 255) {
				return 255;
			}

			return res;
		},
		incrementRGB(r, g, b, index) {
			return {
				r: this.increment(r, index, 0.12),
				g: this.increment(g, index, 0.12),
				b: this.increment(b, index, 0.14),
			};
		},
		truncateText(ctx, text, maxWidth) {
			let truncatedText = text;
			while (ctx.measureText(truncatedText + '...').width > maxWidth && truncatedText.length > 0) {
				truncatedText = truncatedText.slice(0, -1);
			}
			return truncatedText.length < text.length ? truncatedText + '...' : truncatedText;
		},
		drawLabelsAndIndicators(chart) {
			const ctx = chart.ctx;
			const centerX = chart.chartArea.left + chart.chartArea.width / 2;
			const centerY = chart.chartArea.top + chart.chartArea.height / 2;

			// total in the center
			ctx.save();
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.font = 'bold 36px Jost';
			ctx.fillStyle = '#210E21';
			ctx.fillText(this.totalText, centerX, centerY - 10);
			ctx.font = '16px Jost';
			ctx.fillText(this.totalLabel, centerX, centerY + 20);
			ctx.restore();

			chart.data.datasets.forEach((dataset, datasetIndex) => {
				chart.getDatasetMeta(datasetIndex).data.forEach((datapoint, index) => {
					const { x, y, startAngle, endAngle, outerRadius } = datapoint;
					const value = dataset.data[index];
					const angle = (startAngle + endAngle) / 2;

					// circle size based on value
					const circleRadius = Math.max(12, Math.min(17, value / 2));

					// position the circle to intersect with the chart segment
					const labelRadius = outerRadius + circleRadius - 12;
					const labelX = x + Math.cos(angle) * labelRadius;
					const labelY = y + Math.sin(angle) * labelRadius;

					// draw circle
					ctx.beginPath();
					ctx.arc(labelX, labelY, circleRadius, 0, 2 * Math.PI);
					ctx.fillStyle = 'white';
					ctx.fill();
					ctx.strokeStyle = '#C0CADE';
					ctx.lineWidth = 1;
					ctx.stroke();

					// draw value
					ctx.fillStyle = '#210E21';
					ctx.font = `bold ${Math.max(10, Math.min(14, circleRadius))}px Jost`;
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					ctx.fillText(this.allDataZero ? '--' : value.toString(), labelX, labelY);

					// Dynamic label truncation
					let label = chart.data.labels[index];
					ctx.fillStyle = '#949DAF';
					ctx.font = '12px Jost';
					ctx.textAlign = labelX > x ? 'left' : 'right';
					ctx.textBaseline = labelY > y ? 'top' : 'bottom';

					// Calculate available space dynamically
					const maxLabelWidth =
						labelX > x
							? chart.chartArea.right - labelX - 10 // Space to the right
							: labelX - chart.chartArea.left - 10; // Space to the left

					// Truncate text if it exceeds available space
					const truncatedLabel = this.truncateText(ctx, label, maxLabelWidth);

					// Adjust label position
					const labelOffsetX = labelX > x ? circleRadius : -circleRadius;
					const labelOffsetY = labelY > y ? circleRadius : -circleRadius;
					ctx.fillText(truncatedLabel, labelX + labelOffsetX, labelY + labelOffsetY);
				});
			});
		},
		/*Todo: remove this method once we have real API */
		generateMockData() {
			const data = [
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
				faker.number.int({ min: 1, max: 10 }),
			];
			const labels = [
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
				`${faker.location.countryCode()}`,
			];
			return {
				title: '',
				labels: labels,
				datasets: [
					{
						data: data,
						hoverOffset: 4,
					},
				],
			};
		},
	},
};
</script>
